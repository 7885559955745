import ReactSelect, { StylesConfig } from "react-select";

const customStyles: StylesConfig = {
    control: () => ({
        background: "#fff",
        borderRadius: "9999px",
        border: "1px solid #e4e4ed",
        display: "flex",
        padding: "10px 0px 10px 10px",
        textTransform: "capitalize",
        outline: 0,
    }),
};

type SelectProps = {
    placeholder: string;
    options: { value: string; label: string }[];
    setValue: any;
    value: string;
    type: string;
    isMulti: boolean;
};

export default function Select({
    placeholder,
    options,
    setValue,
    value,
    type,
    isMulti,
}: SelectProps) {
    return (
        <ReactSelect
            id={type}
            instanceId={type}
            placeholder={placeholder}
            styles={customStyles}
            options={options}
            value={value}
            onChange={setValue} // send value to hook form
            isClearable={false}
            isMulti={isMulti}
            theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary: "#025FFE",
                },
            })}
        />
    );
}
