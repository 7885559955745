import Newsletter from "./components/Newsletter/Newsletter";
import Menu from "./components/Menu/Menu";
import Copyright from "./components/Copyright/Copyright";

export default function Footer() {
    return (
        <>
            <footer className="px-6 py-6 lg:px-8 md:py-12">
                <Menu />
                <Copyright />
            </footer>
        </>
    );
}
