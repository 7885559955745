import { useEffect, useRef, useState } from "react";
import { newsletterTypes } from "../../../../../common/constants";
import { validateEmail } from "../../../../../common/helper";
import Alert from "../../../Alert/Alert";
import Select from "../../../Select/Select";

export default function Newsletter({ bg }) {
    const [emailType, setEmailType] = useState(null);
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");

    const errorTimeout = useRef(null);
    const validTimeout = useRef(null);

    const sendContact = async () => {
        const isValid = validateEmail(email) && emailType !== null;

        if (isValid) {
            setLoading(true);
            const response = await fetch(
                `/api/email/subscribe?email=${email}&type=${emailType.value}`
            ).then((res) => res.json());
            if (response.data === "success") {
                setLoading(false);
                setEmailSent(true);
                setEmail("");
                validTimeout.current = setTimeout(() => {
                    setEmailSent(false);
                }, 5000);
            }
        } else {
            setError(
                emailType === null
                    ? "Izaberite tip posla"
                    : "Unesite validan email"
            );
            errorTimeout.current = setTimeout(() => {
                setError("");
            }, 5000);
        }
    };

    useEffect(() => {
        return () => {
            clearTimeout(validTimeout.current);
            clearTimeout(errorTimeout.current);
        };
    }, []);

    return (
        <div className={`relative ${bg || "bg-gray-light"} border-gray py-9`}>
            <div className="my-4 md:my-16 max-w-screen-lg px-6 lg:px-0 md:mx-auto">
                <div className="text-center mb-12">
                    <h3 className="text-2xl md:text-4xl md:leading-snug pb-2 font-semibold text-center">
                        Top IT poslovi u tvom inboxu
                    </h3>
                    <p className="font-normal text-gray-dark mt-2">
                        Pretplati se na Dzobs.com newsletter i jednom sedmično
                        ti šaljemo najnovije poslove za odabranu poziciju.
                    </p>
                </div>
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="relative w-full mx-4 mb-4 md:mb-0">
                        <Select
                            type="newsletter"
                            placeholder="Zanimanje..."
                            options={newsletterTypes}
                            value={emailType}
                            setValue={setEmailType}
                            isMulti={false}
                        />
                    </div>
                    <div className="relative w-full mx-4">
                        <input
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email adresa"
                            className="relative w-full border border-gray text-gray-dark hover:border-blue transition-all duration-300 rounded-full h-16 pl-8 pr-4 box-border"
                        />
                        <button
                            type="button"
                            className="absolute btn-primary w-36 top-2 right-4"
                            disabled={loading}
                            onClick={() => sendContact()}
                        >
                            <span className="inline-block">
                                {loading ? "Šaljem..." : "Upiši me"}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            {emailSent && <Alert message="Hvala na prijavi!" type="success" />}
            {error && (
                <Alert type="error" message="Niste ispravno popunili formu." />
            )}
        </div>
    );
}
