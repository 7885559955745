import Link from "next/link";
import {
    cities,
    department,
    levels,
    types,
} from "../../../../../common/constants";

export default function Menu() {
    const half = Math.ceil(cities.length / 2);
    return (
        <div className="grid max-w-screen-xl gap-6 mx-auto md:grid-cols-2 lg:grid-cols-5 lg:gap-x-8">
            <div>
                <h5 className="text-xl font-medium">Lokacije</h5>
                <nav className="mt-4 flex flex-row">
                    <ul className="space-y-2 mr-10">
                        {cities.slice(0, half).map((city) => (
                            <li key={city.slug}>
                                <Link href={`/lokacija/${city.slug}`}>
                                    <a className="text-base text-gray-dark hover:text-blue">
                                        {city.name}
                                    </a>
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <ul className="space-y-2">
                        {cities.slice(-half + 1).map((city) => (
                            <li key={city.slug}>
                                <Link href={`/lokacija/${city.slug}`}>
                                    <a className="text-base text-gray-dark hover:text-blue">
                                        {city.name}
                                    </a>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            <div>
                <h5 className="text-xl font-medium">Iskustvo</h5>
                <nav className="mt-4">
                    <ul className="space-y-2">
                        {levels.map((lvl) => (
                            <li key={lvl.slug}>
                                <Link href={`/iskustvo/${lvl.slug}`}>
                                    <a className="text-base text-gray-dark hover:text-blue">
                                        {lvl.name}
                                    </a>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            <div>
                <h5 className="text-xl font-medium">Pozicija</h5>
                <nav className="mt-4">
                    <ul className="space-y-2">
                        {department.map((dep) => (
                            <li key={dep.slug}>
                                <Link href={`/department/${dep.slug}`}>
                                    <a className="text-base text-gray-dark hover:text-blue">
                                        {dep.name}
                                    </a>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            <div>
                <h5 className="text-xl font-medium">Tip posla</h5>
                <nav className="mt-4">
                    <ul className="space-y-2">
                        {types.map((type) => (
                            <li key={type.slug}>
                                <Link href={`/angazman/${type.slug}`}>
                                    <a className="text-base text-gray-dark hover:text-blue">
                                        {type.name}
                                    </a>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>

            <div>
                <h5 className="text-xl font-medium">Kontakt</h5>
                <div className="mt-4 space-y-4 md:space-y-6">
                    <div className="flex items-start space-x-4">
                        <div>
                            <svg
                                className="w-6 h-6 mt-1 text-gray-dark"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                ></path>
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                ></path>
                            </svg>
                        </div>
                        <div className="flex-1">
                            <div className="text-left text-gray-dark">
                                Boska Tosica 17
                                <br />
                                Banja Luka
                                <br />
                                Bosnia and Herzegovina
                            </div>
                        </div>
                    </div>
                    <div className="flex items-start space-x-4">
                        <div>
                            <svg
                                className="w-6 h-6 mt-1 text-gray-dark"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                ></path>
                            </svg>
                        </div>
                        <div className="flex-1 text-left">
                            <a
                                target="_blank"
                                href="mailto:info@dzobs.com"
                                className="text-gray-dark hover:text-blue"
                            >
                                info@dzobs.com
                            </a>
                            <a
                                target="_blank"
                                href="https://www.linkedin.com/company/dzobs-com/about/"
                                className="text-blue mt-1 block"
                            >
                                LinkedIn
                            </a>
                            <a
                                target="_blank"
                                href="https://www.facebook.com/dzobscom"
                                className="text-blue mt-1 block"
                            >
                                Facebook
                            </a>
                            <a
                                target="_blank"
                                href="https://www.instagram.com/dzobs_com/"
                                className="text-blue mt-1 block"
                            >
                                Instagram
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
